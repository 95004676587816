import React from 'react'
import Styled from 'styled-components'

import H1 from './H1'
import H2 from './H2'
import { ButtonSecondary } from './Button'

import theme from '../theme'
import HideMobile from './HideMobile'
import HideDesktop from './HideDesktop'
import DarkHeroSrc from '../assets/home-work.jpg'
import ArrowDownSrc from '../assets/arrow-down.svg'


const HeroSection = Styled.section`
  height: 800px;
  padding-top: 35px;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  padding-bottom: ${(props) => props.darkTheme ? 150 : 0}px;

  @media (max-width: ${theme.breakpoint}px) {
    height: auto;
  }
`

const HeroSectionContent = Styled.div`
  z-index: 1;
  position: relative;
  width: 90%;
  height: 100%;
  display: block;
  margin: 0 14%;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;
  padding-top: 114px;

  @media (max-width: ${theme.breakpoint}px) {
    flex-direction: column;
    width: calc(100vw - 100px);
    padding-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
  }
`

const HeroButton = Styled.div`
  @media (max-width: ${theme.breakpoint}px) {
    display: flex;
    align-items: center;
  }
`

const ButtonArrow = Styled.a`
  align-self: flex-start;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 0;
  }
`

const H1Overrided = Styled(H1)`
  margin-bottom: 20px;
`

const TitleOffset = Styled.div`
  margin-bottom: 50px;
  margin-left: ${(props) => props.withOffset ? 20 : 0}px;

  @media (max-width: ${theme.breakpoint}px) {
    margin-left: 0;
    margin-bottom: 30px;
  }
`

const ArrowDownImg = Styled.img`
  width: 66px;
  height: 66px;

  @media (max-width: ${theme.breakpoint}px) {
    width: 49px;
    height: 49px;
  }
`

const Img = Styled.img`
  margin-left: 30px;
  max-width: 396px;
  width: 100%;
  ${(props) => props.imgStyle}

  @media (max-width: ${theme.breakpoint}px) {
    margin-left: 0;
    margin-top: 75px;
    max-width: 384px;

    ${(props) => props.imgStyleMobile}
  }
`

const DarkSubtitle = Styled(H2)`
  font-size: 55px;
  max-width: 655px;
  color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 27px;
  }
`


const Hero = ({
  src,
  mask,
  href,
  title,
  button,
  onClick,
  subtitle,
  imgStyle,
  children,
  darkTheme,
  imgStyleMobile,
  subtitleMobile,
  backgroundImage,
}) => {
  let backgroundImageUrl = darkTheme ? `url(${DarkHeroSrc})` : 'transparent';

  if (backgroundImage) {
    backgroundImageUrl = `url(${backgroundImage})`
  }

  return (
    <HeroSection darkTheme={darkTheme} style={{ backgroundImage: backgroundImageUrl }}>
      {mask}
      <HeroSectionContent>
        <div>
          <TitleOffset withOffset={!!button}>
            <H1Overrided>{title}</H1Overrided>
            {subtitle && darkTheme && <DarkSubtitle>{subtitle}</DarkSubtitle>}
            {subtitle && !darkTheme && <H2>{subtitle}</H2>}
          </TitleOffset>

          <HeroButton>
            {button && <ButtonSecondary>{button}</ButtonSecondary>}
            {!button && <ButtonArrow href="#about"><ArrowDownImg src={ArrowDownSrc} /></ButtonArrow>}
          </HeroButton>
        </div>
        {src && <Img src={src} alt={title} imgStyle={imgStyle} imgStyleMobile={imgStyleMobile} />}
      </HeroSectionContent>

      {children}
    </HeroSection>
  )
}


Hero.defaultProps = {
  mask: null,
}


export default Hero
