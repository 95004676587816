import React, { PureComponent, createRef } from 'react'
import Styled from 'styled-components'

import theme from '../theme'


const Container = Styled.div`
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;

  & > * {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    display: inline-block;
    margin-left: 50px;
    margin-right: 50px;

    @media (max-width: ${theme.breakpoint}px) {
      margin-left: 20px;
      margin-right: 20px;      
    }
  }
`


class Slider extends PureComponent {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the PureComponent
   */
  constructor(props) {
    super(props)

    this.state = {
      step: 0,
    }

    this.interval = null
    this.container = createRef()
    this.autoplayInterval = null
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    this.interval = window.setInterval(this.onTouchMove, 500)

    if (this.props.duration) {
      this.autoplayInterval = window.setInterval(this.nextStep, this.props.duration)
    }
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    window.clearInterval(this.interval)
    window.clearInterval(this.autoplayInterval)
  }


  /* METHODS */

  onTouchMove = () => {
    const { current: container } = this.container

    if (container) {
      const widthRef = container.children[0].clientWidth
      const activeIndex = Math.ceil(container.scrollLeft / (widthRef + 30))

      if (activeIndex !== this.state.activeIndex) {
        this.setState({
          activeIndex,
        })
      }
    }
  }

  nextStep = () => {
    const { activeIndex } = this.state
    const { current: container } = this.container

    if (container) {
      const widthRef = container.children[0].clientWidth
      const count = container.children.length
      const nextActiveIndex = activeIndex + 1 >= count ? 0 : activeIndex + 1
      const nextChild = container.children[nextActiveIndex]
      const { x: defaultX, left } = nextChild.getBoundingClientRect()
      const x = defaultX || left

      if (container.scrollTo) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth',
          left: (widthRef + 30) * nextActiveIndex,
        })

        this.setState({ activeIndex: nextActiveIndex })
      }
    }
  }


  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const {
      children,
      className,
    } = this.props

    return (
      <Container
        ref={this.container}
        className={className}
      >
        {children}
      </Container>
    )
  }
}


export default Slider
