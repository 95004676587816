import Styled from 'styled-components'

import theme from '../theme'


const H4 = Styled.h4`
  font-size: 36px;
  margin-left: 60px;
  color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 28px;
    margin-left: 0;
  }
`


export default H4
