import React from 'react'
import Styled from 'styled-components'

import theme from '../theme'


const Container = Styled.figure`
  width: 100%;
  max-width: 275px;
  text-align: center;
`

const IconContainer = Styled.div`
  margin: 0 auto;
  position: relative;
  width: ${(props) => props.backgroundDisabled ? 'auto' : '250px'};
  padding-top: 96%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.backgroundDisabled ? 0 : 50}%;
  background-color: ${(props) => props.backgroundDisabled ? 'transparent' : theme.color.cream};

  & > * {
    left: 50%;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: translateX(-50%);
  }

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 76%;
  }
`

const MouseHover = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  position: absolute;
  background-color: transparent;
  transition: background-color ${theme.transition};

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`


const Caption = Styled.figcaption`
  display: block;
  margin-top: 40px;
  text-align: center;
  font-family: ${theme.typography.circular};
  color: ${theme.color.almostBlack};
  font-size: ${theme.typography.caption}px;
`

const SubText = Styled.p`
  opacity: 0.8;
  margin-top: 10px;
  text-align: center;
  color: ${theme.color.lightDarkBlue};
  font-size: ${theme.typography.bookSize}px;
  font-family: ${theme.typography.circular};
`


const Badge = ({
  img,
  title,
  subtitle,
  children,
  disableImg,
  mouseHover,
  captionStyle,
  subtitleStyle,
  disableBackground,
}) => (
  <Container>
    {!disableImg && (
      <IconContainer
        backgroundDisabled={disableBackground}
      >
        {img}
        {mouseHover && <MouseHover />}
      </IconContainer>
    )}
    {children}
    <Caption dangerouslySetInnerHTML={{ __html: title }} style={captionStyle} />
    <SubText style={subtitleStyle} dangerouslySetInnerHTML={{ __html: subtitle }} />
  </Container>
)


export default Badge
