import Styled from 'styled-components'

import theme from '../theme'


const H3 = Styled.h3`
  font-size: 46px;
  font-weight: bold;
  color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 28px;
  }
`


export default H3
