import React from 'react'
import Styled from 'styled-components'

import theme from '../theme'
import StarSrc from '../assets/stars.svg'


const Container = Styled.div`
  vertical-align: top;
  display: inline-block;
  padding: 10px;
  max-width: 60vw;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${theme.breakpoint}px) {
    max-width: 75vw;    
  }
`

const Title = Styled.p`
  font-size: 35px;
  white-space: normal;
  color: ${theme.color.black};
  font-family: ${theme.typography.gascogne};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 22px;
  }
`

const Provider = Styled.p`
  font-size: 15px;
  margin-top: 20px;
`

const Img = Styled.img`
  width: 260px;
  margin-top: 10px;

  @media (max-width: ${theme.breakpoint}px) {
    width: 130px;
  }
`


const Like = ({
  title,
  provider,
}) => (
  <Container>
    <Title>« {title} »</Title>
    <Img src={StarSrc} />
    <Provider>{provider}</Provider>
  </Container>
)


export default Like
