import React from 'react'
import Styled from 'styled-components'
import { Link } from 'gatsby'

import H3 from '../components/H3'
import H4 from '../components/H4'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Like from '../components/Like'
import Badge from '../components/Badge'
import Layout from '../layouts/MainLayout'
import HideDesktop from '../components/HideDesktop'
import Slider from '../components/Slider'
import {
  ButtonDark,
  ButtonBlue,
  ButtonWhite,
  ButtonSecondary,
} from '../components/Button'
import { openDeviceApp } from '../helper'
import { GIFT_LINK } from '../constant'

import theme from '../theme'
import Cloud1Src from '../assets/Cloud-1.png'
import Cloud2Src from '../assets/Cloud-2.png'
import Cloud3Src from '../assets/Cloud-3.png'
import MeditatorMobileSrc from '../assets/meditators-mobile.jpg'
import MeditatorDesktopSrc from '../assets/meditators.jpg'
import FlowersSrc from '../assets/flowers.png'
import Badge1Src from '../assets/Perso-1.png'
import Badge2Src from '../assets/Groupe-1.png'
import Badge3Src from '../assets/Groupe-2.png'
import Badge4Src from '../assets/kid1.png'
import HomeSrc from '../assets/home.png'
import FlowerSrc from '../assets/Flower.png'
import Kid1Src from '../assets/INTRO.png'
import Kid2Src from '../assets/2-Yoga.png'
import Kid3Src from '../assets/contes-1.jpeg'
import Kid4Src from '../assets/eXERCICES-2.png'
import CloudSrc from '../assets/Calque-236.png'
import Subscribe6Src from '../assets/ABONNEMENT_6.png'
import Subscribe1Src from '../assets/ABONNEMENT_12.png'
import SubscribeInfiniteSrc from '../assets/ABONNEMENT_FULL.png'

const BadgeSection = Styled.section`
  z-index: 2;
  position: relative;
  padding-top: 115px;
  padding-bottom: 116px;
  margin-top: -50px;
  border-radius: ${theme.radius.big}px;
  background-color: ${theme.color.primary};

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: -405px;
  }
`

const BadgeSectionContent = Styled.div`
  width: 90%;
  z-index: 1;
  margin: 0 auto;
  position: relative;
  max-width: ${theme.grid.fullWidth}px;
`

const BadgeContainer = Styled.div`
  display: flex;
  margin-top: 110px;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 10px;
    flex-wrap: wrap;

    & > * {
      margin-top: 100px;
      width: calc(50% - 10px);
      margin-right: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
`

const BadgeImg = Styled.img`
  max-width: 100%;
  max-height: 100%;
`

const BadgeImgMin = Styled.img`
  top: auto;
  left: 50%;
  max-width: 75%;
  max-height: 75%;
  transform: translateX(-50%);
`

const BadgeButtonContainer = Styled.div`
  display: flex;
  margin-top: 126px;
  align-items: center;
  justify-content: center;
`

const CloudBackground = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${(props) => (props.transparent ? 0.2 : 0.3)};
  position: absolute;
  background-size: 50%;
  background-position: -18% 84%;
  border-radius: ${(props) => (props.transparent ? theme.radius.big : 0)}px;
  background-color: ${(props) => props.transparent ? theme.color.primary : theme.color.alternativeWhite};
  background-blend-mode: ${(props) => props.transparent ? 'multiply' : 'normal'};

  @media (max-width: ${theme.breakpoint}px) {
    background-size: cover;
    background-position: center;
  }
`

const LibrarySection = Styled.section`
  position: relative;
  margin-top: -${theme.radius.big}px;
  background-color: ${theme.color.darkBlue};
`

const LibrarySectionContent = Styled.div`
  width: 90%;
  z-index: 1;
  margin: 0 auto;
  padding: 230px 0;
  padding-top: ${115 + theme.radius.big}px;
  position: relative;
  max-width: ${theme.grid.fullWidth}px;

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 110px;
  }
`

const LibrarySectionGrid = Styled.div`
  display: flex;
  margin-top: 115px;
  align-items: center;
  justify-content: space-between;
`

const LibrarySectionButton = Styled.div`
  margin-top: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LibrarySectionParagraphContainer = Styled.div`
  max-width: 650px;

  & > * {
    margin-top: 30px;
    color: ${theme.color.alternativeWhite};
    font-size: ${theme.typography.alternative}px;

    &:first-child {
      margin-top: 0;
    }
  }
`

const LibraryOfferContainer = Styled.div`
  width: 100%;
  max-width: 400px;

  & > * {
    width: 100%;
    /* margin-bottom: 45px; */
    margin-bottom: -70px;

    /*
    &:last-child {
      margin-bottom: 0;
    }
    */

    &:first-child {
      margin-top: 0;
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const LibraryItem = Styled.p`
  font-size: 22px;
  display: block;
  position: relative;
  padding-left: 36px;
  color: ${theme.color.alternativeWhite};

  &::after {
    content: '';
    display: block;
    width: 23px;
    height: 16px;
    left: 0;
    top: 50%;
    position: absolute;
    background-size: cover;
    transform: translateY(-50%);
    background-position: center;
    background-image: url(${FlowerSrc});
  }
`

const KidSection = Styled.section`
  z-index: 1;
  margin-top: -50px;
  position: relative;
  padding-bottom: 160px;
  border-radius: ${theme.radius.big}px;
  background-color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    padding-bottom: 110px;
  }
`

const KidSectionContainer = Styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 115px;
  max-width: ${theme.grid.fullWidth}px;

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 110px;
    padding-bottom: 0;
  }
`

const SectionHeader = Styled.div`
  display: flex;
  padding-left: 75px;
  align-items: center;
  padding-right: 35px;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoint}px) {
    padding-left: 0;
    align-items: flex-start;
    flex-direction: column;

    & > *:last-child {
      margin-top: 35px;
    }
  }
`

const KidSectionTitle = Styled(H4)`
  max-width: 280px;
  color: ${theme.color.green};
`

const KidSectionText = Styled.p`
  max-width: 500px;
`

const KidImagesContainer = Styled.div`
  margin-top: 86px;
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoint}px) {
    width: 100%;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }
`

const KidImageOverlay = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: ${theme.radius.normal}px;
  transition: opacity ${theme.transition};

  & > * {
    margin-left: 0;
    text-align: center;
    color: ${theme.color.white};
  }
`

const KidImage = Styled.div`
  width: 90%;
  max-width: 296px;
  min-height: 100px;
  padding-top: 36.5%;
  position: relative;
  border-radius: ${theme.radius.normal}px;

  &:hover ${KidImageOverlay} {
    opacity: 1;
  }

  @media (max-width: ${theme.breakpoint}px) {
    width: 80%;
    height: 512px;
    max-width: 384px;
    margin-right: 32px;
    display: inline-block;
    scroll-snap-align: start;
    scroll-snap-stop: normal;

    &:last-child {
      margin-right: 0;
    }
  }
`

const KidImageBackground = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-size: cover;
  background-position: center;
`

const KidButtonContainer = Styled.div`
  display: flex;
  align-items: center;
`

const KidButton = Styled(ButtonSecondary)`
  margin: 0 auto;
  margin-top: 135px;
  background-color: ${theme.color.green};

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 110px;
  }
`

const ExpertSection = Styled.section`
  margin-top: -${theme.radius.big}px;
  padding-top: ${115 + theme.radius.big}px;
  background-color: ${theme.color.lightPrimary};

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 110px;
  }
`

const ExpertSectionContainer = Styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: ${theme.grid.fullWidth}px;
`

const ExpertSectionHeader = Styled(SectionHeader)`
  padding-bottom: 60px;
`

const ExpertSectionTitle = Styled(KidSectionTitle)`
  color: ${theme.color.secondary};
`

const ExpertSectionText = Styled(KidSectionText)`
  color: rgba(246, 83, 30, 0.8);
`

const SectionLike = Styled.section`
  z-index: 2;
  position: relative;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  background-color: ${theme.color.white};

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

const FlowerBackground = Styled.div`
  left: 0;
  right: 0;
  z-index: 0;
  bottom: -20px;
  height: 176px;
  position: absolute;
  background-size: contain;
  background-image: url(${FlowersSrc});
  background-repeat-x: repeat;
`

const Meditators = Styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1440px;
  position: relative;
`

const MeditatorMobile = Styled.img`
  display: none;

  @media (max-width: ${theme.breakpoint}px) {
    display: block;
    width: 100%;
  }
`

const MeditatorDesktop = Styled.img`
  width: 100%;

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const ButtonWhiteMeditator = Styled(ButtonWhite)`
  left: 50%;
  z-index: 5;
  bottom: 30px;
  position: absolute;
  transform: translateX(-50%);

  @media (max-width: ${theme.breakpoint}px) {
    display: block;
    margin: 22px auto;
    position: static;
    transform: none;
  }
`

const Cloud1Img = Styled.img`
  left: 0;
  width: 60%;
  bottom: -10%;
  z-index: -1;
  position: absolute;
`

const Cloud2Img = Styled.img`
  top: 12%;
  left: 53%;
  width: 120px;
  z-index: -1;
  position: absolute;
`

const Cloud3Img = Styled.img`
  top: 10%;
  right: 0;
  height: 213px;
  z-index: -1;
  position: absolute;
`

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Découvrez l'application" />

    <Hero
      src={HomeSrc}
      title="Découvrez Mind"
      subtitle="La méditation pour vivre mieux"
      button={(
        <HideDesktop>
          <a href="" onClick={openDeviceApp}>
            Télécharger Mind
          </a>
        </HideDesktop>
      )}
      mask={<CloudBackground style={{ backgroundImage: `url(${CloudSrc}` }} />}
    />

    <BadgeSection id="about">
      <CloudBackground
        transparent
        style={{ backgroundImage: `url(${CloudSrc}` }}
      />
      <BadgeSectionContent>
        <H4>
          Des méditations
          <br />
          pour toute la famille
        </H4>

        <BadgeContainer>
          <Badge
            mouseHover
            img={<BadgeImgMin src={Badge1Src} />}
            title="Des programmes d'initiation"
            subtitle="Pour bien démarrer la méditation"
          />
          <Badge
            mouseHover
            img={<BadgeImg src={Badge2Src} />}
            title="Mieux <br />dormir"
            subtitle="avec des séances spéciales Sommeil"
          />
          <Badge
            mouseHover
            img={<BadgeImg src={Badge3Src} />}
            title="+ de 450 séances guidées"
            subtitle="pour approfondir votre pratique"
          />
          <Badge
            mouseHover
            img={<BadgeImg src={Badge4Src} />}
            title="Des programmes pour les kids"
            subtitle="à faire en famille"
          />
        </BadgeContainer>

        <HideDesktop>
          <BadgeButtonContainer>
            <ButtonDark>
              <a href="" onClick={openDeviceApp}>
                Télécharger Mind
              </a>
            </ButtonDark>
          </BadgeButtonContainer>
        </HideDesktop>
      </BadgeSectionContent>
    </BadgeSection>

    <LibrarySection>
      <LibrarySectionContent>
        {/* <LibrarySectionImg src={FootSrc} /> */}
        <H3>
          Accédez à toute
          <br />
          la bibliothèque Mind
        </H3>

        <LibrarySectionGrid>
          <LibrarySectionParagraphContainer>
            <LibraryItem>
              3 programmes d'initiation pour commencer la méditation
            </LibraryItem>
            <LibraryItem>Une méditation Live chaque jour</LibraryItem>
            <LibraryItem>Des séances anti-stress</LibraryItem>
            <LibraryItem>Des podcasts pour explorer la méditation</LibraryItem>
            <LibraryItem>Un programme sur le sommeil</LibraryItem>
            <LibraryItem>
              Des programmes sur la créativité, l'auto-compassion ou encore le
              bonheur
            </LibraryItem>
            <LibraryItem>4 mini programmes pour les Kids</LibraryItem>
          </LibrarySectionParagraphContainer>

          <LibraryOfferContainer>
            {/* <img src={Subscribe6Src} alt="Abonnement de 6 mois à 39 €" /> */}
            <img src={Subscribe1Src} alt="Abonnement de 1 an à 69 €" />
            <img src={SubscribeInfiniteSrc} alt="Abonnement à vie à 190 €" />
          </LibraryOfferContainer>
        </LibrarySectionGrid>

        <LibrarySectionButton>
          <ButtonBlue>
            <a href={GIFT_LINK} target="_blank">
              Je m'abonne
            </a>
          </ButtonBlue>
        </LibrarySectionButton>
      </LibrarySectionContent>
      <FlowerBackground />
    </LibrarySection>

    <KidSection>
      <KidSectionContainer>
        <SectionHeader>
          <KidSectionTitle>Des programmes pour les Kids.</KidSectionTitle>
          <KidSectionText>
            4 programmes spécialement conçus par Eline Snel, l'auteure du
            best-seller Calme et attentif comme une grenouille.
          </KidSectionText>
        </SectionHeader>

        <KidImagesContainer>
          <KidImage>
            <KidImageBackground
              style={{ backgroundImage: `url(${Kid1Src})` }}
            />
            <KidImageOverlay>
              <H4>Méditations guidées</H4>
            </KidImageOverlay>
          </KidImage>
          <KidImage>
            <KidImageBackground
              style={{ backgroundImage: `url(${Kid2Src})` }}
            />
            <KidImageOverlay>
              <H4>Yoga</H4>
            </KidImageOverlay>
          </KidImage>
          <KidImage>
            <KidImageBackground
              style={{ backgroundImage: `url(${Kid3Src})` }}
            />
            <KidImageOverlay>
              <H4>Contes</H4>
            </KidImageOverlay>
          </KidImage>
          <KidImage>
            <KidImageBackground
              style={{ backgroundImage: `url(${Kid4Src})` }}
            />
            <KidImageOverlay>
              <H4>Jeux</H4>
            </KidImageOverlay>
          </KidImage>
        </KidImagesContainer>

        <HideDesktop>
          <KidButtonContainer>
            <KidButton>
              <a href="" onClick={openDeviceApp} target="_blank">
                Méditer avec mes enfants !
              </a>
            </KidButton>
          </KidButtonContainer>
        </HideDesktop>
      </KidSectionContainer>
    </KidSection>

    <ExpertSection>
      <ExpertSectionContainer>
        <ExpertSectionHeader>
          <ExpertSectionTitle>
            Des experts
            <br />
            de la méditation
          </ExpertSectionTitle>
          <ExpertSectionText>
            Des instructeurs de mindfulness expérimentés vous proposent des
            méditations guidées et des enseignements sous forme de podcasts.
          </ExpertSectionText>
        </ExpertSectionHeader>
      </ExpertSectionContainer>

      <Meditators>
        <MeditatorDesktop src={MeditatorDesktopSrc} />
        <MeditatorMobile src={MeditatorMobileSrc} />
        <ButtonWhiteMeditator>
          <Link to="/experts-mind">Voir les experts mind</Link>
        </ButtonWhiteMeditator>
      </Meditators>
    </ExpertSection>

    <SectionLike>
      <Cloud1Img src={Cloud1Src} />
      <Cloud2Img src={Cloud2Src} />
      <Cloud3Img src={Cloud3Src} />

      <Slider duration={10000}>
        <Like
          title="Appli parfaite pour démarrer la méditation"
          provider="Just750618sd"
        />
        <Like
          title="La meilleure application pour méditer. Des contenus riches, variés, une application facile à utiliser..."
          provider="Elise2205"
        />
        <Like
          title="Cette application nous rend meilleur."
          provider="dubreed"
        />
        <Like
          title="La qualité des intervenants est d'un très grand niveau."
          provider="bernard3075"
        />
        <Like
          title="15 minutes par jour qui changent la vie."
          provider="lacourge13"
        />
        <Like
          title="Devrait être prescrit à tout le monde !"
          provider="Antonin7512"
        />
        <Like
          title="Je ne pensais pas que la méditation pouvait  bouleverser ma vie à ce point."
          provider="CHNOU03"
        />
        <Like title="Un bon anti stress." provider="mariamamz" />
        <Like title="M'aide à m'endormir" provider="marie.seck20" />
        <Like
          title="Un vrai support facile, accessible et bienveillant pour apprendre à pratiquer la méditation. Une vraie bulle d'air 😊🙏"
          provider="kandinsky99"
        />
        <Like
          title="Très adaptée pour les débutants en méditation. J'adore !"
          provider="Jjg"
        />
      </Slider>
    </SectionLike>
  </Layout>
)

export default IndexPage
